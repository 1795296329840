@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


body {
  font-family: 'Roboto', sans-serif;
}

.testing {
  margin: 0 auto;
}

@layer components {

  a {
    @apply text-yellow-400 transition duration-200 ease-in-out;
  }

  a:hover {
    @apply text-yellow-500;
  }

  .turbo-progress-bar {
    height: 5px;
    background-color: green;
  }

  .beevelo-dashboard {
    
    //
    // Inputs
    //

    &.auth {
      .control {
        @apply appearance-none relative block w-full border border-gray-300 placeholder-gray-500 text-gray-900 transition duration-200 ease-in-out;

        &.control-lg {
          @apply px-3 py-3 text-lg;
        }

      }

      .control:focus {
        @apply outline-none ring-2 z-10 ring-gray-400 border-gray-400;

        &.control-info:focus {
          @apply ring-blue-400 border-blue-400;
        }
        &.control-success:focus {
          @apply ring-green-500 border-green-500;
        }
        &.control-bee:focus {
          @apply ring-yellow-400 border-yellow-400;
        }
      }
    }

    .form-control {
      @apply block w-full min-w-0 rounded sm:text-sm text-gray-700 border-gray-300 transition duration-200 ease-in-out;

      &.error {
        @apply border-red-300 text-red-900 placeholder-red-300;
      }

    }

    .form-control:focus {
      @apply ring-bee-yellow border-bee-yellow;

      &.info {
        @apply border-blue-500 ring-blue-500;
      }

      &.error {
        @apply ring-red-500 border-red-500;
      }

      &.brown {
        @apply border-bee-brown ring-bee-brown;
      }
    }

    .form-control-search {
      @apply block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-500 placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm font-bold;
    }


    //
    // Checkboxes
    //

    .form-checkbox {
      @apply h-4 w-4 text-blue-600 border-gray-300 rounded;
    }

    .form-checkbox:focus {
      @apply ring-blue-500;
    }


    //
    // Selects
    //

    .form-select {
      @apply block w-full pl-3 pr-10 py-2 text-base text-gray-700 border-gray-300 sm:text-sm rounded-md shadow-sm;
    }

    .form-select:focus {
      @apply outline-none ring-bee-brown border-bee-brown;
    }

    //
    // Buttons
    //

    .auth {
      .btn {
        @apply relative w-full py-2 border border-transparent text-base font-medium rounded-md transition duration-500 ease-in-out;

        // Size
        &.btn-lg {
          @apply px-4 text-lg;
        }

        // Color
        &.btn-info {
          @apply text-white bg-blue-500;
        }
      }

      .btn:hover {
        // Color
        &.btn-info {
          @apply bg-blue-600;
        }
      }

      .btn:focus {
        @apply outline-none ring-2 ring-offset-2;

        // Color
        &.btn-info {
          @apply ring-blue-500;
        }
      }
    }

    .btn {
      @apply bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-xs sm:text-sm font-medium text-gray-700 transition duration-500 ease-in-out cursor-pointer;

      &.btn-info {
        @apply border-transparent text-white bg-blue-600;
      }

      &.btn-danger {
        @apply border-transparent text-white bg-rose-600;
      }

      &.btn-warning {
        @apply border-transparent text-white bg-yellow-400;
      }

      &.btn-black {
        @apply border-transparent text-white bg-gray-600;
      }

      &.btn-bee-yellow {
        @apply border-transparent text-bee-brown bg-yellow-300;
      }

      &.btn-bee-brown {
        @apply border-transparent text-white bg-bee-brown;
      }

    }

    .btn:focus {
      @apply outline-none ring-2 ring-offset-2 ring-gray-500;

      &.btn-info {
        @apply ring-blue-500;
      }

      &.btn-danger {
        @apply ring-rose-500;
      }

      &.btn-warning {
        @apply ring-yellow-300;
      }

      &.btn-black {
        @apply ring-gray-500;
      }

      &.btn-bee-yellow {
        @apply ring-yellow-200;
      }

      &.btn-bee-brown {
        @apply ring-bee-brown;
      }

    }

    .btn:hover {
      @apply bg-gray-50 text-gray-700;

      &.btn-info {
        @apply bg-blue-700 text-white;
      }

      &.btn-danger {
        @apply bg-rose-700 text-white;
      }

      &.btn-warning {
        @apply bg-yellow-500 text-white;
      }

      &.btn-black {
        @apply bg-gray-700 text-white;
      }

      &.btn-bee-yellow {
        @apply bg-yellow-400 text-bee-brown;
      }

      &.btn-bee-brown {
        @apply bg-bee-brown text-gray-300;
      }

    }


    //
    // Checkbox
    //

    .checkbox {
      @apply h-4 w-4 text-bee-brown border-gray-300 rounded;

      &.checkbox-disabled {
        @apply text-gray-400;
      }
    }

    .checkbox:focus {
      @apply ring-bee-brown;
    }


    //
    // Alerts
    //

    .alert {
      @apply relative py-4 px-4 rounded-lg;

      // Colors
      &.alert-danger {
        @apply bg-red-200 text-red-900;
      }

      h2 {
        @apply font-semibold mb-1;

        &.title {
          @apply text-xl;
        }
      }

      ul {
        @apply list-inside list-disc;
      }
    }


    //
    // Dropdown
    //

    .dropdown {
      @apply origin-top-right z-40 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5;

      .item {
        @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100;
      }
    }


    //
    // Pagination
    //

    .paginator {
      @apply relative z-0 inline-flex shadow-sm -space-x-px;

      .button {
        @apply relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50;

        &.first {
          @apply rounded-l-md;
        }
        &.last {
          @apply rounded-r-md;
        }
        &.active {
          @apply text-blue-500 font-extrabold;
        }
      }
    }


    //
    // Tabs
    //

    .tabs {
      .active {
        @apply transition duration-200 ease-in flex items-center bg-[rgba(39,0,0,0.2)] text-bee-brown px-3 py-2 font-medium text-sm rounded-md;
      }
      .inactive {
        @apply transition duration-200 ease-in flex items-center text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md;
      }
    }

    .numberTabs {
      .active {
        @apply whitespace-nowrap flex py-2 px-6 border-b-2 font-medium text-sm border-bee-yellow text-bee-yellow;
        span {
          @apply bg-yellow-200;
        }
      }
      .inactive {
        @apply whitespace-nowrap flex py-2 px-6 border-b-2 font-medium text-sm border-transparent text-gray-500 hover:text-bee-brown hover:border-bee-brown;
        span {
          @apply bg-gray-100;
        }
      }
    }


    //
    // Dashboard
    //

    .menu-dashboard {
      .not_active {
        @apply transition text-gray-300 hover:bg-bee-yellow hover:text-bee-brown text-sm flex items-center px-2 py-2 font-medium rounded-md;

        svg {
          @apply transition text-gray-400 mr-3 h-6 w-6;
        }

        svg:hover {
          @apply text-bee-brown;
        }

        div {
          @apply border-gray-400
        }

        div:hover {
          @apply border-bee-brown
        }
      }
      .active {
        @apply bg-bee-gray text-bee-brown flex items-center px-2 py-2 text-sm font-medium rounded-md;

        svg {
          @apply text-bee-brown mr-3 h-6 w-6;
        }

        svg:hover {
          @apply text-bee-brown;
        }

        div {
          @apply border-bee-brown
        }

        div:hover {
          @apply border-bee-brown
        }
      }
    }


    //
    // Success and danger labels
    //

    .label {
      @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize;

      &.label-success {
        @apply bg-green-100 text-green-800;
      }

      &.label-danger {
        @apply bg-rose-100 text-rose-800;
      }

      &.label-warning {
        @apply bg-yellow-100 text-yellow-800;
      }
    }
  }

}

