@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

//Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//Glide.js
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";


@font-face {
  font-family: Condiment;
  src:url(../../assets/fonts/Condiment-Regular.ttf);
}

@layer components {
   /*a {
    @apply text-blue-500 transition duration-200 ease-in-out;
  }

  a:hover {
    @apply text-blue-600;
  } */

  .turbo-progress-bar {
    height: 5px;
    background-color: green;
  }

  .cursor-zoom-in {
    cursor: zoom-in;
  }

  .veil {
    background-color: rgba(0,0,0,0.2) !important;
  }

  .zoom {
    @apply absolute top-[10%] left-[12%] w-[76%] h-auto z-50 border-2 border-solid border-bee-brown rounded cursor-pointer;
  }
  
  .no-zoom {
    @apply cursor-pointer h-full w-auto;
  }

  .overlay-screen {
    @apply fixed hidden w-full h-full top-0 left-0 right-0 bottom-0 z-[2] bg-[rgba(0,0,0,0.5)];
  }

  .page-size {
    @apply px-4 mx-auto max-w-7xl ;
  }

  
  //
  //Tooltips
  //

  .tooltip {
    @apply invisible absolute;
  }

  .tooltip-container:hover .tooltip {
    @apply visible z-50;
  }

  //
  // Inputs
  //

  .auth {
    .control {
      @apply appearance-none relative block w-full border border-gray-300 placeholder-gray-500 text-gray-900 transition duration-200 ease-in-out;

      &.control-lg {
        @apply px-3 py-3 text-lg;
      }
    }

    .control:focus {
      @apply outline-none ring-2 z-10 ring-gray-400 border-gray-400;

      &.control-info:focus {
        @apply ring-blue-400 border-blue-400;
      }
      &.control-success:focus {
        @apply ring-green-500 border-green-500;
      }
    }
  }

  .form-control {
    @apply block w-full min-w-0 rounded sm:text-sm border-gray-300 transition duration-200 ease-in-out;

    &.error {
      @apply border-red-300 text-red-900 placeholder-red-300;
    }

    &.disabled {
      @apply border-gray-300 text-gray-300;
    }

  }

  .form-control:focus {
    @apply ring-gray-500 border-gray-500;

    &.info {
      @apply border-blue-500 ring-blue-500;
    }

    &.error {
      @apply ring-red-500 border-red-500;
    }

    &.disabled {
      @apply border-gray-300 ring-0 text-gray-300;
    }

  }

  .form-control-search {
    @apply block w-full h-full pl-8 pr-3 py-2 border-transparent text-blue-500 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm font-bold;
  }


  //
  // Checkboxes
  //

  .form-checkbox {
    @apply h-4 w-4 text-blue-600 border-gray-300 rounded;
  }

  .form-checkbox:focus {
    @apply ring-blue-500;
  }


  //
  // Selects
  //

  .form-select {
    @apply block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md;

    &.disabled {
      @apply border-gray-300 text-gray-300;
    }

  }

  .form-select:focus {
    @apply outline-none ring-blue-500 border-blue-500;

    &.disabled {
      @apply border-gray-300 text-gray-300;
    }

  }

  .finder-select {
    @apply border-bee-brown border rounded-xl w-full
  }

  .finder-select:focus {
    @apply border-bee-yellow ring-bee-yellow
  }


  //
  //Autocomplete  
  //

  .list-group {
    @apply max-h-40 overflow-y-auto border-[1px] rounded;

    .active {
      @apply outline-none bg-blue-400;
    }
    
  }


  //
  // Inputs - error
  //

  .field_with_errors {
    width: 100%;
  }

  .error-message {
    @apply mt-2 text-xs text-red-600;
  }

  //
  // Buttons
  //

  .auth {
    .btn {
      @apply relative w-full py-2 border border-transparent text-base font-medium rounded-md transition duration-500 ease-in-out;

      // Size
      &.btn-lg {
        @apply px-4 text-lg;
      }

      &.btn-sm {
        @apply text-sm;
      }

      // Color
      &.btn-info {
        @apply text-white bg-blue-500;
      }
    }

    .btn:hover {
      // Color
      &.btn-info {
        @apply bg-blue-600;
      }
    }

    .btn:focus {
      @apply outline-none ring-2 ring-offset-2;

      // Color
      &.btn-info {
        @apply ring-blue-500;
      }
    }
  }

  .btn {
    @apply bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 transition duration-500 ease-in-out cursor-pointer;

    &.btn-sm {
      @apply text-xs px-2 py-1;
    }

    &.btn-info {
      @apply border-transparent text-white bg-blue-600;
    }

    &.btn-danger {
      @apply border-transparent text-white bg-rose-600;
    }

    &.btn-warning {
      @apply border-transparent text-white bg-yellow-400;
    }

    &.btn-black {
      @apply border-transparent text-white bg-gray-600;
    }

    &.btn-green {
      @apply border-transparent text-bee-brown bg-[#64DD17];
    }
    
    &.btn-bee-white {
      @apply text-bee-brown bg-white font-semibold border-bee-yellow ring-bee-yellow;
    }

    &.btn-bee-yellow {
      @apply border-bee-yellow ring-bee-yellow border-2 font-semibold text-bee-brown bg-bee-yellow;
    }

  }

  .btn:focus {
    @apply outline-none ring-2 ring-offset-2 ring-gray-500;

    &.btn-info {
      @apply ring-blue-500;
    }

    &.btn-danger {
      @apply ring-rose-500;
    }

    &.btn-warning {
      @apply ring-yellow-300;
    }

    &.btn-black {
      @apply ring-gray-500;
    }

    &.btn-green {
      @apply ring-green-500;
    }

    &.btn-bee-white {
      @apply ring-bee-yellow;
    }

    &.btn-bee-yellow {
      @apply ring-yellow-200;
    }

  }

  .btn:hover {
    @apply bg-gray-50 text-gray-700;

    &.btn-info {
      @apply bg-blue-700 text-white;
    }

    &.btn-danger {
      @apply bg-rose-700 text-white;
    }

    &.btn-warning {
      @apply bg-yellow-500 text-white;
    }

    &.btn-black {
      @apply bg-gray-700 text-white;
    }

    &.btn-green {
      @apply bg-[#689F38] text-bee-brown;
    }

    &.btn-bee-white {
      @apply bg-bee-gray;
    }

    &.btn-bee-yellow {
      @apply bg-yellow-400 text-bee-brown;
    }

  }


  //
  // Checkbox
  //

  .checkbox {
    @apply h-4 w-4 text-blue-600 border-gray-300 rounded;
  }

  .checkbox:focus {
    @apply ring-blue-500;
  }


  //
  // Alerts
  //

  .alert {
    @apply relative py-4 px-4 rounded-lg;

    // Colors
    &.alert-danger {
      @apply bg-red-200 text-red-900;
    }

    h2 {
      @apply font-semibold mb-1;

      &.title {
        @apply text-xl;
      }
    }

    ul {
      @apply list-inside list-disc;
    }
  }


  //
  // Dropdown
  //

  .dropdown {
    @apply origin-top-right z-40 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5;

    .item {
      @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100;
    }
  }

  .mobile_dropdown {
    @apply flex flex-col gap-5 p-5;

    .item {
      @apply text-black text-sm font-bold whitespace-nowrap;
    }
  }


  //
  // Pagination
  //

  .paginator {
    @apply relative z-0 inline-flex shadow-sm -space-x-px;

    .button {
      @apply relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50;

      &.first {
        @apply rounded-l-md;
      }
      &.last {
        @apply rounded-r-md;
      }
      &.active {
        @apply text-blue-500 font-extrabold;
      }
    }
  }

  .public-paginator {
    @apply relative z-0 inline-flex -space-x-px bg-white;

    .button {
      @apply relative inline-flex items-center p-2 text-sm font-medium text-bee-yellow hover:text-bee-brown hover:font-extrabold;

      &.active {
        @apply text-bee-brown font-extrabold;
      }
    }
  }


  //
  // Tabs
  //

  .tabs {
    .active {
      @apply transition duration-200 ease-in bg-blue-100 text-blue-700 px-3 py-2 font-medium text-sm rounded-md;
    }
    .inactive {
      @apply transition duration-200 ease-in text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md;
    }
  }

  .publicTabs {
    .active {
      @apply transition duration-200 ease-in bg-bee-brown text-white px-3 py-2 font-medium text-sm rounded-md;
    }
    .inactive {
      @apply transition duration-200 ease-in text-gray-500 hover:text-bee-brown px-3 py-2 font-medium text-sm rounded-md;
    }
  }


  //
  // Dashboard
  //

  .menu-dashboard {
    .not_active {
      @apply transition text-gray-300 hover:bg-gray-700 hover:text-white text-sm flex items-center px-2 py-2 font-medium rounded-md;

      svg {
        @apply transition text-gray-400 mr-3 h-6 w-6;
      }

      svg:hover {
        @apply text-gray-300;
      }
    }
    .active {
      @apply bg-gray-900 text-white flex items-center px-2 py-2 text-sm font-medium rounded-md;

      svg {
        @apply text-gray-300 mr-3 h-6 w-6;
      }

      svg:hover {
        @apply text-gray-200;
      }
    }
  }


  //
  // Success and danger labels
  //

  .label {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize;

    &.label-success {
      @apply bg-green-100 text-green-800;
    }

    &.label-danger {
      @apply bg-rose-100 text-rose-800;
    }

    &.label-warning {
      @apply bg-yellow-100 text-yellow-800;
    }
  }
}